body,
html {
  padding: 0;
  margin: 0;
  position: relative;
  min-height: 100vh;
}

/* give the gap between header and footer 
on bg-img included page to avoid overlapping */
.header-bg-included {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 60rem;
}

/* Remove arrow in number input style start */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Remove arrow in number input style end */

/* Homepage background image style on large screen */
/* FIXME: will update the code with better approach in the future */
@media screen and (min-width: 2561px) {
  .bg-img-home-trustedby {
    height: 1850px !important;
  }
  .bg-img-home-footer {
    height: 2600px !important;
  }
  .bg-img-employer-home-trustedby {
    height: 2600px !important;
  }
  .bg-img-employer-home-footer {
    height: 3050px !important;
  }
}
@media screen and (min-width: 3001px) {
  .bg-img-home-trustedby {
    height: 2050px !important;
  }
  .bg-img-home-footer {
    height: 2750px !important;
  }
  .bg-img-employer-home-trustedby {
    height: 2750px !important;
  }
  .bg-img-employer-home-footer {
    height: 3150px !important;
  }
}
@media screen and (min-width: 3501px) {
  .bg-img-home-trustedby {
    height: 2200px !important;
  }
  .bg-img-home-footer {
    height: 2850px !important;
  }
  .bg-img-employer-home-trustedby {
    height: 2850px !important;
  }
  .bg-img-employer-home-footer {
    height: 3250px !important;
  }
}

/* Table Rows on Employer Dashboard*/
.additional-column {
  visibility: hidden;
}

.hoverable-row:hover .additional-column {
  visibility: visible;
}

/* Animation related to Splash Screen */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.__floater__arrow {
  animation: fade-in 0.5s ease-in-out;
}
