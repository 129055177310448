.wysiwyg-toolbar {
  background-color: transparent;
  border: 1px solid #ced3e0;
  border-radius: 4px;
  font-family: "Poppins", sans-serif;
}

.wysiwyg-editor {
  background-color: transparent;
  border: 1px solid #ced3e0;
  border-radius: 4px;
  padding: 0 0.5rem;
  font-family: "Poppins", sans-serif;
  min-height: 15rem;
}

.wysiwyg-editor:hover {
  border: 1px solid #7b828b;
}

.wysiwyg-editor:focus,
.wysiwyg-editor:focus-within {
  border: 1px solid #1859ff;
  outline: 2px solid #1859ff33;
}

.wisiwyg-viewer {
  font-family: "Poppins", sans-serif;
}

.wysiwyg-wrapper {
  margin-top: 12px;
}

.wysiwyg-toolbar .rdw-option-wrapper,
.wysiwyg-toolbar .rdw-dropdown-wrapper {
  border: none;
  box-shadow: none;
  background-color: transparent;
  border-radius: 4px;
}

.wysiwyg-toolbar .rdw-dropdown-optionwrapper {
  border: none;
  box-shadow: none;
}

.wysiwyg-toolbar .rdw-option-wrapper:hover,
.wysiwyg-toolbar .rdw-dropdown-wrapper:hover {
  background-color: #1859ff0a;
}

.wysiwyg-toolbar .rdw-option-active {
  background-color: #1859ff33 !important;
}

.disabled-ui {
  pointer-events: none;
  color: #7b828b;
}

/* FIXME: Add Typography with Responsiveness here also */
