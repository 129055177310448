:root {
  --white: #ecf0f2;
  --blueish-white: #f8fafc;
  --gray: #ced3e0;
  --orange: #e74c3c;
  --purple: #78378c;
  --blue: #3c91e7;
  --shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
  --rounded-corners: 4px;
  --rounded-corners-large: 8px;
}

@keyframes loading-animate {
  0% {
    background-position-x: 0;
  }

  to {
    background-position-x: 200%;
  }
}

/* PDF Message */

.react-pdf__message {
  width: 100%;
  aspect-ratio: 1 / 1.4142;
  text-align: center;
}

.react-pdf__message--loading {
  background: linear-gradient(
    125.75deg,
    white 0%,
    var(--gray) 33%,
    white 66%,
    var(--gray) 100%
  );
  background-size: 200% 100%;
  animation-name: loading-animate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  font-size: 0;
}

/* PDF Containers */

.pdf-container {
  width: 100%;
  height: 100%;
  /*Note: --component-height is not defined */
  margin-top: calc(var(--component-height) / -2);
  margin-bottom: 4em;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  font-size: 19px;
  overflow: hidden;
}

.pdf-thumbnail-container {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  font-size: 19px;
}

.pdf-modal-container {
  width: 100%;
  height: 100%;
}

/* PDF Document */

.react-pdf__Document {
  border-radius: var(--rounded-corners-large);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fff;
  overflow: hidden;
}

/* PDF Page */

.react-pdf__Page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.react-pdf__Page.rendering {
  position: absolute !important;
  background-color: transparent !important;
  opacity: 0;
  z-index: -1;
}

.react-pdf__Page.departuring {
  opacity: 0.5;
  transition: opacity ease-in-out 0.2s;
}

.react-pdf__Page__canvas {
  width: 100%;
  height: 100%;
  display: block;
  background-color: #fff;
  overflow-y: auto;
}

.react-pdf__Page--modal {
  padding: 24px;
}

.pdf-modal-page {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--rounded-corners);
  height: min-content;
  width: calc(100% - 50px);
  flex-grow: 1;
  flex-shrink: 0;
  padding: 24px;
  background-color: var(--gray);
  overflow-y: auto;
  margin: auto;
}

.pdf-modal-page-container {
  border-radius: var(--rounded-corners);
  background-color: var(--gray);
  display: flex;
  height: 100%;
  overflow: auto;
}

/* Loading */

.react-pdf__Document--loading,
.react-pdf__Page--loading {
  background: linear-gradient(
    125.75deg,
    white 0%,
    var(--gray) 33%,
    white 66%,
    var(--gray) 100%
  );
  width: 100%;
  background-size: 200% 100%;
  animation-name: loading-animate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  font-size: 0;
}

/* Page Control Buttons */

.page-controls {
  position: absolute;
  bottom: 5%;
  left: 50%;
  display: flex;
  align-items: center;
  opacity: 1;
  transform: translate(-50%);
  transition: opacity ease-in-out 0.2s;
  background-color: #fff;
  border-radius: var(--rounded-corners);
  z-index: 2;
}

.page-controls span {
  font-size: 0.6em;
  padding: 0 0.5em;
}

.page-controls button {
  width: 30px;
  height: 30px;
  background: white;
  border: 0;
  border: 1px solid var(--gray);
  border-radius: var(--rounded-corners);
}

.page-controls button:enabled:hover {
  cursor: pointer;
}

.page-controls button:enabled:hover,
.page-controls button:enabled:focus {
  background-color: #e6e6e6;
}

.page-controls button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.page-controls button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
